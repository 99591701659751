
















import { Component, Vue, Watch } from 'vue-property-decorator';
import { dispatchCheckLoggedIn, dispatchGetConfig, readIsLoggedIn } from '@/store/main/accessors';
import NotificationsManager from '@/components/NotificationsManager.vue';
import { updateToken } from '@/plugins/keycloak-util';
import axios from 'axios';

@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {
  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async created() {
    await dispatchCheckLoggedIn(this.$store);
    await dispatchGetConfig(this.$store);

    axios.interceptors.request.use(async (config) => {
      const token = await updateToken();
      config.headers.common['Authorization'] = `Bearer ${token}`;
      return config;
    });

    axios.interceptors.response.use((response) => {
      return response;
    });
  }

  @Watch('$route')
  public async routerChange() {
    console.log('Route has Changed');
    await updateToken();
  }
}
