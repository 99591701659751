import 'core-js/stable';
import 'regenerator-runtime/runtime';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import DatetimePicker from 'vuetify-datetime-picker';
import './plugins/vuetify';
import './plugins/vee-validate';
import './plugins/emp-auth';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import './assets/css/custom.css';
import './assets/css/countries-flags.css';
import './plugins/keycloak';
import { updateToken } from './plugins/keycloak-util';
import '@fontsource/material-icons';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import './assets/css/material-icons.css';

Vue.use(DatetimePicker);
Vue.config.productionTip = false;

(Vue as any).$empAuthPlugin
  .init()
  .then(() => (Vue as any).$keycloak.init({ onLoad: 'login-required' }))
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
      }).$mount('#app');

      window.onfocus = () => {
        updateToken();
      };
    }
  });
