import axios, { AxiosError, AxiosPromise } from 'axios';
import axiosRetry from 'axios-retry';
import * as cookie from 'cookie';

const API_PREFIX = '/api/v1';
const DEFAULT_RETRIES_COUNT = 5;

function isAxiosError(candidate: unknown): candidate is AxiosError<{ status: string }> {
  if (candidate && typeof candidate === 'object' && 'isAxiosError' in candidate) {
    return true;
  }
  return false;
}

class EmpAuth {
  private client = axios.create();
  private validateTokenRequest: AxiosPromise | null = null;

  constructor() {
    axiosRetry(this.client, {
      retries: Number(process.env.VUE_APP_EMP_TOKEN_VALIDATE_RETRIES_COUNT) || DEFAULT_RETRIES_COUNT,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error: AxiosError) => {
        return Number(error.response?.status) >= 500;
      },
    });
  }

  private get token() {
    const cookies = cookie.parse(document.cookie);
    return cookies[process.env.VUE_APP_EMP_TOKEN_COOKIE_NAME] || '';
  }

  public async checkIsTokenValid(): Promise<boolean> {
    if (!this.token) {
      console.log('EMPAuth token undefined', this.token);
      return false;
    }

    const validateMethodPath = process.env.VUE_APP_EMP_TOKEN_VALIDATE_METHOD_PATH;
    if (!validateMethodPath) {
      throw new Error('Не задан параметр EMP_TOKEN_VALIDATE_METHOD_PATH');
    }

    try {
      if (!this.validateTokenRequest) {
        this.validateTokenRequest = this.client.post(validateMethodPath, {
          token: this.token,
        });
        this.validateTokenRequest.then(() => {
          this.validateTokenRequest = null;
        });
      }
      const response = await this.validateTokenRequest;

      if (response.status !== 200) {
        console.log('EMPAuth token invalid');
        return false;
      }

      console.log('EMPAuth token valid');
      return true;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        return false;
      }
      throw error;
    }
  }

  public async createEmpAxiosInterceptor() {
    axios.interceptors.request.use(async (config) => {
      if (config.url?.includes(API_PREFIX) && !this.token) {
        console.log('Отсутствует токен EMP');
        this.redirectToAuthPage();
        return Promise.reject(new Error('Отсутствует токен EMP'));
      }

      return config;
    });
  }

  public redirectToAuthPage() {
    window.location.href = process.env.VUE_APP_EMP_AUTH_PAGE;
  }
}

export const empAuth = new EmpAuth();
