import Vue, { PluginObject } from 'vue';
import { empAuth } from './EmpAuth';

const empAuthPlugin = {
  init: async () => {
    const isActive = process.env.VUE_APP_EMP_AUTH_ENABLE === 'true';
    console.log('empAuth init', isActive);
    if (!isActive) return;

    try {
      document.getElementById('app')!.innerHTML = 'Аутентификация в EMP';
      const isTokenValid = await empAuth.checkIsTokenValid();
      if (!isTokenValid) {
        empAuth.redirectToAuthPage();
        throw new Error('authenticationRequired');
      }

      empAuth.createEmpAxiosInterceptor();
      console.log('authenticated');
    } catch (error) {
      document.getElementById('app')!.innerHTML = 'Ошибка аутентификации в EMP';
      throw error;
    }
  },
};

const Plugin: PluginObject<any> = {
  install: (Vue) => {
    (Vue as any).$empAuthPlugin = empAuthPlugin;
  },
};

Plugin.install = (Vue) => {
  (Vue as any).$empAuthPlugin = empAuthPlugin;
  Object.defineProperties(Vue.prototype, {
    $empAuthPlugin: {
      get() {
        return empAuthPlugin;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
