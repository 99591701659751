import { getStoreAccessors } from 'typesafe-vuex';
import { MainState } from '../state';
import { State } from '@/store/state';
import { actions } from '../actions';

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchCreateMessage = dispatch(actions.actionCreateMessage);
export const dispatchUploadReceiversFile = dispatch(actions.actionUploadReceiversFile);
// newsletter block
export const dispatchCreateNewsletter = dispatch(actions.actionCreateNewslatter);
export const dispatchCreateNewsletterMessages = dispatch(actions.actionCreateNewslatterMessages);
export const dispatchGetNewsletter = dispatch(actions.actionGetNewsletter);
export const dispatchGetNewslettersList = dispatch(actions.actionGetNewslettersList);
export const dispatchGetNewsletterMessages = dispatch(actions.actionGetNewsletterMessages);
export const dispatchDeleteNewsletter = dispatch(actions.actionDeleteNewsletter);
export const dispatchUpdateNewsletter = dispatch(actions.actionUpdateNewsletter);
export const dispatchUploadNewsletterReceiversFile = dispatch(actions.actionUploadNewsletterReceiversFile);
// blacklist block
export const dispatchGetBlacklists = dispatch(actions.actionGetBlacklists);
export const dispatchGetBlacklistAddresses = dispatch(actions.actionGetBlacklistAddresses);
export const dispatchCreateBlacklist = dispatch(actions.actionCreateBlacklist);
export const dispatchCreateBlacklistAddresses = dispatch(actions.actionCreateBlacklistAddresses);
export const dispatchUploadBlacklistAddressesFile = dispatch(actions.actionUploadBlacklistAddressesFile);
export const dispatchDeleteBlacklist = dispatch(actions.actionDeleteBlacklist);
export const dispatchDeleteBlacklistAddresses = dispatch(actions.actionDeleteBlacklistAddresses);
export const dispatchGetBlacklist = dispatch(actions.actionGetBlacklist);
export const dispatchUpdateBlacklist = dispatch(actions.actionUpdateBlacklist);
// seq-sends block
export const dispatchGetSeqSends = dispatch(actions.actionGetSeqSends);
export const dispatchGetSeqSendItem = dispatch(actions.actionGetSeqSendItem);
export const dispatchCreateSeqSend = dispatch(actions.actionCreateSeqSend);
export const dispatchUpdateSeqSend = dispatch(actions.actionUpdateSeqSend);
export const dispatchDeleteSeqSend = dispatch(actions.actionDeleteSeqSend);
export const dispatchGetSeqSendSteps = dispatch(actions.actionGetSeqSendSteps);
export const dispatchCreateSeqSendStep = dispatch(actions.actionCreateSeqSendStep);
export const dispatchUpdateSeqSendStep = dispatch(actions.actionUpdateSeqSendStep);
export const dispatchDeleteSeqSendStep = dispatch(actions.actionDeleteSeqSendStep);
export const dispatchSeqSendStepOrderChange = dispatch(actions.actionSeqSendStepOrderChange);
// config
export const dispatchGetConfig = dispatch(actions.actionGetConfig);
// rate-limits block
export const dispatchGetRateLimit = dispatch(actions.actionGetRateLimit);
export const dispatchGetRateLimits = dispatch(actions.actionGetRateLimitsList);
export const dispatchCreateRateLimit = dispatch(actions.actionCreateRateLimit);
export const dispatchUpdateRateLimit = dispatch(actions.actionUpdateRateLimit);
export const dispatchDeleteRateLimit = dispatch(actions.actionDeleteRateLimit);
